import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import i18n from './i18n'

const Home = () => import('./views/Home.vue')
const About = () => import('./views/About.vue')
const Coverage = () => import('./views/Coverage.vue')
const VsatInternetSatellite = () => import('./views/VsatInternetSatellite.vue')
const ServicesCorporateNetworksOverSatellite = () => import('./views/Services/CorporateNetworksOverSatellite.vue')
const ServicesMaritimeSatelliteInternet = () => import('./views/Services/MaritimeSatelliteInternet.vue')
const ServicesMssInmarsatAndIridium = () => import('./views/Services/MssInmarsatAndIridium.vue')
const ServicesOccasionalInternetAndIpStreaming = () => import('./views/Services/OccasionalInternetAndIpStreaming.vue')
const ServicesSatelliteBroadbandInternet = () => import('./views/Services/SatelliteBroadbandInternet.vue')
const ServicesSystemsIntegration = () => import('./views/Services/SystemsIntegration.vue')
const ServicesTelemetryAndM2mOverSatellite = () => import('./views/Services/TelemetryAndM2mOverSatellite.vue')
const ServicesVpnOverSatellite = () => import('./views/Services/VpnOverSatellite.vue')
const Contact = () => import('./views/Contact.vue')
const Error404 = () => import('./views/Error404.vue')
const Erdf = () => import('./views/Erdf.vue')
const Fse = () => import('./views/Fse.vue')
const Legal = () => import('./views/Legal.vue')
const ResearchAndDevelopment = () => import('./views/ResearchAndDevelopment.vue')

Vue.use(Router)
Vue.use(Meta)

const defaultRoutes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/about',
        name: 'about',
        component: About
    },

    {
        path: '/services/corporate-networks-over-satellite',
        name: 'services_corporate_networks_over_satellite',
        component: ServicesCorporateNetworksOverSatellite
    },

    {
        path: '/services/maritime-satellite-internet',
        name: 'services_maritime_satellite_internet',
        component: ServicesMaritimeSatelliteInternet
    },

    {
        path: '/services/mss-inmarsat-and-iridium',
        name: 'services_mss_inmarsat_and_iridium',
        component: ServicesMssInmarsatAndIridium
    },

    {
        path: '/services/occasional-internet-and-ip-streaming',
        name: 'services_occasional_internet_and_ip_streaming',
        component: ServicesOccasionalInternetAndIpStreaming
    },

    {
        path: '/services/satellite-broadband-internet',
        name: 'services_satellite_broadband_internet',
        component: ServicesSatelliteBroadbandInternet
    },

    {
        path: '/services/systems-integration',
        name: 'services_systems_integration',
        component: ServicesSystemsIntegration
    },

    {
        path: '/services/telemetry-and-m2m-over-satellite',
        name: 'services_telemetry_and_m2m_over_satellite',
        component: ServicesTelemetryAndM2mOverSatellite
    },

    {
        path: '/services/vpn-over-satellite',
        name: 'services_vpn_over_satellite',
        component: ServicesVpnOverSatellite
    },

    {
        path: '/coverage',
        name: 'coverage',
        component: Coverage
    },

    {
        path: '/vsat-internet-satellite/:country_slug',
        name: 'vsat_internet_satellite',
        component: VsatInternetSatellite
    },

    {
        path: '/contact',
        name: 'contact',
        component: Contact
    },

    {
        path: '/erdf',
        name: 'erdf',
        component: Erdf
    },

    {
        path: '/fse',
        name: 'fse',
        component: Fse
    },

    {
        path: '/legal',
        name: 'legal',
        component: Legal
    },

    {
        path: '/r-and-d',
        name: 'research_and_development',
        component: ResearchAndDevelopment
    },

    {
        path: "*",
        name: "error_404",
        component: Error404
    },
]

const languages = i18n.availableLocales // get the available languages
const defaultLanguage = 'es'
const routes = [] // Should contain all the routes
languages.forEach(lang => {
    defaultRoutes.forEach(route => {
        let localizedPath = i18n.te('route.' + route.name, lang) ? i18n.t('route.' + route.name, lang) : route.path
        if (lang !== defaultLanguage) {
            localizedPath = '/' + lang + '/' + localizedPath.replace(/^\/+/g, '')
        }
        routes.push({
            path: localizedPath,
            name: lang + '_' + route.name,
            component: route.component
        })
    })
})

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routes,
    scrollBehavior (to) {
        if (to.hash) {
            return {
                selector: to.hash
            }
        }
        return { x: 0, y: 0 }
    }
})

router.beforeEach((to, from, next) => {
    let forcedRouteName = null

    if (to.name) {
        const lang = to.name.split('_')[0]

        if (!languages.includes(lang)) {
            forcedRouteName = i18n.locale + '_' +  to.name
        }

        // if (!languages.includes(lang)) return next({ name: 'es_home' })

        if (i18n.locale !== lang) {
            i18n.locale = lang
        }
    }

    if (forcedRouteName) {
        return next({name: forcedRouteName})
    }

    return next()
})

export default router
