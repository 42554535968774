<template>
    <b-navbar-nav class="navbar-social d-none d-md-flex">
        <b-nav-item
                v-for="(lang, index) in languages"
                :active="lang === $i18n.locale"
                :key="lang"
                @click="changeLang(lang)"
                class="nav-item-language"
                :class="index === languages.length - 1 ? 'last' : null"
        >
            {{ lang }}
        </b-nav-item>
        <b-nav-item href="https://twitter.com/syntelix" class="nav-item-social" :link-attrs="{ 'aria-label': 'Syntelix Twitter' }">
            <span class="li-twitter"></span>
        </b-nav-item>
        <b-nav-item href="https://www.linkedin.com/company/syntelix" class="nav-item-social last" :link-attrs="{ 'aria-label': 'Syntelix LinkedIn' }">
            <span class="li-linkedin"></span>
        </b-nav-item>
    </b-navbar-nav>
</template>

<script>
    import countries from '@/coverage.json'
    export default {
        name: "LanguageSwitcher",
        data: () => ({
            languages: ['es', 'en'],
            countries: countries
        }),
        methods: {
            changeLang (lang) {
                if ('undefined' !== typeof this.$route.name) {
                    const newPathName = lang + this.$route.name.substr(2)
                    const newParams = this.$route.params

                    if ('undefined' !== typeof newParams.country_slug) {
                        newParams.country_slug = this.translateCountrySlug(newParams.country_slug, this.$i18n.locale, lang)
                    }

                    if (newPathName !== this.$route.name) {
                        this.$router.push({ name: newPathName, params: newParams })
                    }
                } else {
                    this.$i18n.locale = lang
                }
            },

            translateCountrySlug(slug, fromLang, toLang) {
                const code = this.countries[fromLang].filter(country => {
                    return country.slug === slug
                })[0].code

                const matches = this.countries[toLang].filter(country => {
                    return country.code === code
                })

                return matches[0].slug
            }
        }
    }
</script>

<style scoped>

</style>
