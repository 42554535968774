<template>
  <div id="app" class="app">
    <b-navbar ref="navbarMain" toggleable="md" type="light" fixed="top" class="navbar-main navbar-light" :class="{ collapsed: scrollPosition > 50, opaque:  $route.name && $route.name.includes('_contact') }">
      <b-container style="position: relative">
        <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>

        <b-navbar-brand :to="{ name: $i18n.locale + '_home' }">
          <img alt="Syntelix" src="https://syntelix-website.s3-eu-west-1.amazonaws.com/logo.svg" />
          <b-dropdown right size="lg" variant="link" toggle-class="text-decoration-none" no-caret class="ml-3 ue-flag d-md-none d-inline">
            <template #button-content>
              <b-img fluid :src="require('@/assets/images/ue.svg')" :alt="$t('message.erdf.title')"></b-img>
            </template>
            <b-dropdown-item :to="{ name: $i18n.locale + '_erdf' }">{{ $t('message.footer.erdf_programs') }}</b-dropdown-item>
            <b-dropdown-item :to="{ name: $i18n.locale + '_fse' }">{{ $t('message.footer.fse_programs') }}</b-dropdown-item>
            <b-dropdown-item :to="{ name: $i18n.locale + '_research_and_development' }">{{ $t('message.footer.r_and_d_projects') }}</b-dropdown-item>
          </b-dropdown>
        </b-navbar-brand>

        <b-collapse is-nav id="nav_collapse">
          <LanguageSwitcher></LanguageSwitcher>

          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <b-nav-item
                    :active="$route.name === $i18n.locale + '_home'"
                    :to="{ name: $i18n.locale + '_home' }">
              {{ $t('message.home.title') }}
            </b-nav-item>
            <b-nav-item
                    :active="$route.name === $i18n.locale + '_about'"
                    :to="{ name: $i18n.locale + '_about' }">
              {{ $t('message.about_us.title') }}
            </b-nav-item>
            <b-nav-item-dropdown :text="$t('message.services.title')">
              <b-dropdown-item
                      :active="$route.name === $i18n.locale + '_services_satellite_broadband_internet'"
                      :to="{ name: $i18n.locale + '_services_satellite_broadband_internet' }">
                {{ $t('message.services.satellite_broadband_internet.title') }}
              </b-dropdown-item>
              <b-dropdown-item
                      :active="$route.name === $i18n.locale + '_services_vpn_over_satellite'"
                      :to="{ name: $i18n.locale + '_services_vpn_over_satellite' }">
                {{ $t('message.services.vpn_over_satellite.title') }}
              </b-dropdown-item>
              <b-dropdown-item
                      :active="$route.name === $i18n.locale + '_services_telemetry_and_m2m_over_satellite'"
                      :to="{ name: $i18n.locale + '_services_telemetry_and_m2m_over_satellite' }">
                {{ $t('message.services.telemetry_and_m2m_over_satellite.title') }}
              </b-dropdown-item>
              <b-dropdown-item
                      :active="$route.name === $i18n.locale + '_services_maritime_satellite_internet'"
                      :to="{ name: $i18n.locale + '_services_maritime_satellite_internet' }">
                {{ $t('message.services.maritime_satellite_internet.title') }}
              </b-dropdown-item>
              <b-dropdown-item
                      :active="$route.name === $i18n.locale + '_services_occasional_internet_and_ip_streaming'"
                      :to="{ name: $i18n.locale + '_services_occasional_internet_and_ip_streaming' }">
                {{ $t('message.services.occasional_internet_and_ip_streaming.title') }}
              </b-dropdown-item>
              <b-dropdown-item
                      :active="$route.name === $i18n.locale + '_services_corporate_networks_over_satellite'"
                      :to="{ name: $i18n.locale + '_services_corporate_networks_over_satellite' }">
                {{ $t('message.services.corporate_networks_over_satellite.title') }}
              </b-dropdown-item>
              <b-dropdown-item
                      :active="$route.name === $i18n.locale + '_services_mss_inmarsat_and_iridium'"
                      :to="{ name: $i18n.locale + '_services_mss_inmarsat_and_iridium' }">
                {{ $t('message.services.mss_inmarsat_and_iridium.title') }}
              </b-dropdown-item>
              <b-dropdown-item
                      :active="$route.name === $i18n.locale + '_services_systems_integration'"
                      :to="{ name: $i18n.locale + '_services_systems_integration' }">
                {{ $t('message.services.systems_integration.title') }}
              </b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item
                    :active="$route.name === $i18n.locale + '_coverage'"
                    :to="{ name: $i18n.locale + '_coverage' }">
              {{ $t('message.coverage.title') }}
            </b-nav-item>
            <b-nav-item
                    :active="$route.name === $i18n.locale + '_contact'"
                    :to="{ name: $i18n.locale + '_contact' }">
              {{ $t('message.contact.title') }}
            </b-nav-item>
          </b-navbar-nav>
        </b-collapse>
        <b-dropdown right size="lg" variant="link" toggle-class="text-decoration-none" no-caret class="ue-flag d-none d-md-block ml-2">
          <template #button-content>
              <b-img fluid :src="require('@/assets/images/ue.svg')" :alt="$t('message.erdf.title')"></b-img>
          </template>
          <b-dropdown-item :to="{ name: $i18n.locale + '_erdf' }">{{ $t('message.footer.erdf_programs') }}</b-dropdown-item>
          <b-dropdown-item :to="{ name: $i18n.locale + '_fse' }">{{ $t('message.footer.fse_programs') }}</b-dropdown-item>
          <b-dropdown-item :to="{ name: $i18n.locale + '_research_and_development' }">{{ $t('message.footer.r_and_d_projects') }}</b-dropdown-item>
        </b-dropdown>

      </b-container>
    </b-navbar>
    <router-view></router-view>
    <footer>
      <section class="section">
        <b-container>
          <img class="footer-logo" src="https://syntelix-website.s3-eu-west-1.amazonaws.com/logo-inverse.svg" alt="Syntelix">
          <b-row>
            <b-col md="4" lg="4" xl="3">
              <h4 class="text-uppercase">{{ $t('message.contact.headquarters_emea.title') }}</h4>
              <address>
                <ul class="fa-ul">
                  <li>
                    <span class="fa-li li-map-pin"></span>
                    <span v-html="$t('message.contact.headquarters_emea.address')"></span>
                  </li>
                  <li><span class="fa-li li-phone"></span>{{ $t('message.contact.headquarters_emea.phone') }}</li>
                  <li><span class="fa-li li-envelope"></span><a :href="'mailto: ' + $t('message.contact.headquarters_emea.email')">{{ $t('message.contact.headquarters_emea.email') }}</a></li>
                </ul>
              </address>

              <h4 class="text-uppercase">{{ $t('message.contact.headquarters_americas.title') }}</h4>
              <address>
                <ul class="fa-ul">
                  <li>
                    <span class="fa-li li-map-pin"></span>
                    <span v-html="$t('message.contact.headquarters_americas.address')"></span>
                  </li>
                  <li><span class="fa-li li-phone"></span>{{ $t('message.contact.headquarters_americas.phone') }}</li>
                  <li><span class="fa-li li-envelope"></span><a :href="'mailto: ' + $t('message.contact.headquarters_americas.email')">{{ $t('message.contact.headquarters_americas.email') }}</a></li>
                </ul>
              </address>
            </b-col>

            <b-col md="8" lg="4" xl="6">
              <b-row>
                <b-col md="7" lg="12" xl="7">
                  <h4 class="text-uppercase">{{ $t('message.services.title') }}</h4>
                  <ul class="fa-ul">
                    <li>
                      <span class="fa-li fa fa-angle-right"></span>
                      <router-link :to="{ name: $i18n.locale + '_services_satellite_broadband_internet' }">
                        {{ $t('message.services.satellite_broadband_internet.title') }}
                      </router-link>
                    </li>
                    <li>
                      <span class="fa-li fa fa-angle-right"></span>
                      <router-link :to="{ name: $i18n.locale + '_services_vpn_over_satellite' }">
                        {{ $t('message.services.vpn_over_satellite.title') }}
                      </router-link>
                    </li>
                    <li>
                      <span class="fa-li fa fa-angle-right"></span>
                      <router-link :to="{ name: $i18n.locale + '_services_telemetry_and_m2m_over_satellite' }">
                        {{ $t('message.services.telemetry_and_m2m_over_satellite.title') }}
                      </router-link>
                    </li>
                    <li>
                      <span class="fa-li fa fa-angle-right"></span>
                      <router-link :to="{ name: $i18n.locale + '_services_maritime_satellite_internet' }">
                        {{ $t('message.services.maritime_satellite_internet.title') }}
                      </router-link>
                    </li>
                    <li>
                      <span class="fa-li fa fa-angle-right"></span>
                      <router-link :to="{ name: $i18n.locale + '_services_occasional_internet_and_ip_streaming' }">
                        {{ $t('message.services.occasional_internet_and_ip_streaming.title') }}
                      </router-link>
                    </li>
                    <li>
                      <span class="fa-li fa fa-angle-right"></span>
                      <router-link :to="{ name: $i18n.locale + '_services_corporate_networks_over_satellite' }">
                        {{ $t('message.services.corporate_networks_over_satellite.title') }}
                      </router-link>
                    </li>
                    <li>
                      <span class="fa-li fa fa-angle-right"></span>
                      <router-link :to="{ name: $i18n.locale + '_services_mss_inmarsat_and_iridium' }">
                        {{ $t('message.services.mss_inmarsat_and_iridium.title') }}
                      </router-link>
                    </li>
                    <li>
                      <span class="fa-li fa fa-angle-right"></span>
                      <router-link :to="{ name: $i18n.locale + '_services_systems_integration' }">
                        {{ $t('message.services.systems_integration.title') }}
                      </router-link>
                    </li>
                  </ul>
                </b-col>
                <b-col md="5" lg="12" xl="5"><h4 class="text-uppercase">{{ $t('message.footer.about_us') }}</h4>
                  <ul class="fa-ul">
                    <li><span class="fa-li fa fa-angle-right"></span><router-link :to="{ name: $i18n.locale + '_about' }">{{ $t('message.footer.company') }}</router-link></li>
                    <li><span class="fa-li fa fa-angle-right"></span><router-link :to="{ name: $i18n.locale + '_coverage' }">{{ $t('message.footer.coverage') }}</router-link></li>
                    <li><span class="fa-li fa fa-angle-right"></span><router-link :to="{ name: $i18n.locale + '_contact' }">{{ $t('message.footer.contact') }}</router-link></li>
                    <li><span class="fa-li fa fa-angle-right"></span><router-link :to="{ name: $i18n.locale + '_erdf' }">{{ $t('message.footer.erdf_programs') }}</router-link></li>
                    <li><span class="fa-li fa fa-angle-right"></span><router-link :to="{ name: $i18n.locale + '_fse' }">{{ $t('message.footer.fse_programs') }}</router-link></li>
                    <li><span class="fa-li fa fa-angle-right"></span><router-link :to="{ name: $i18n.locale + '_research_and_development' }">{{ $t('message.footer.r_and_d_projects') }}</router-link></li>
                  </ul>
                  <a href="https://twitter.com/syntelix" target="_blank" rel="noopener" class="mr-2" aria-label="Syntelix Twitter"><span class="li-twitter fa-2x"></span></a>
                  <a href="https://www.linkedin.com/company/syntelix" target="_blank" rel="noopener"><span class="li-linkedin fa-2x" aria-label="Syntelix LinkedIn"></span></a><br><br>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="12" lg="4" xl="3">
              <div class="text-center" title="Sello PYME INNOVADORA 27/06/2022">
                <a target="_blank" rel="noopener" href="https://sede.micinn.gob.es/pyiINFO/buscarPyi.mec?&nif=B84989854" title="Ir a 'Sello Pyme Innovadora con validez 27/06/2022 ' en ventana nueva">
                <div class="d-flex justify-content-center">
                  <div style="max-width: 142px">
                    <img class="img-fluid"  alt="Sello PYME INNOVADORA 27/06/2022" :src="require('@/assets/images/innovative_sme.png')"/>

                  </div>
                </div>
                <div class="my-2">
                  <div class="font-weight-bold text-uppercase" style="font-size: 16px;">{{ $t('message.footer.innovative_sme.title') }}</div>
                  <div style="font-size: 10px;">{{ $t('message.footer.innovative_sme.date') }}</div>
                </div>
                <div><img class="img-fluid" alt="escudo de MEIC 27/06/2022" src="https://sede.micinn.gob.es/pyiINFO/imagenes/escudo.jpg"/></div>
              </a></div>
            </b-col>
          </b-row>
        </b-container>
      </section>
      <section class="footer-legal">
        <b-container>
          <b-row>
            <b-col xl="8">Syntelix © Syntelix Avances Tecnológicos S.L. 2013 - {{ (new Date()).getFullYear() }}. {{ $t('message.footer.all_rights_reserved') }}.</b-col>
            <b-col xl="4" class="text-right">
              <router-link :to="{ name: $i18n.locale +  '_legal', hash: '#legal-notice' }">{{ $t('message.footer.legal_notice') }}</router-link>
              • <router-link :to="{ name: $i18n.locale +  '_legal', hash: '#cookies-policy' }">{{ $t('message.footer.cookies') }}</router-link>
              • <router-link :to="{ name: $i18n.locale +  '_legal', hash: '#privacy-policy' }">{{ $t('message.footer.privacy') }}</router-link>
            </b-col>
          </b-row>
        </b-container>
      </section>
    </footer>
    <div class="cookies-alert" v-if="showCookiesAlert">
      <button class="close" type="button" aria-label="close" @click="showCookiesAlert = false"><span aria-hidden="true">×</span></button>
      <p v-html="$t('message.cookies_alert', { link: $router.resolve({ name: $i18n.locale +  '_legal', hash: '#cookies-policy' }).route.fullPath })"></p>
    </div>
  </div>
</template>

<script>
  import './scss/app.scss'
  import './assets/fonts/light-icons/style.css'
  import LanguageSwitcher from "./components/LanguageSwitcher";
  export default {
    name: 'App',
    components: { LanguageSwitcher },
    data () {
      return {
        scrollPosition: 0,
        showCookiesAlert: true
      }
    },

    created () {
      if ('true' === this.$cookies.get('accept_cookies')) {
        this.showCookiesAlert = false
      }
      this.$cookies.set('accept_cookies', 'true')
    },

    mounted () {
      window.addEventListener('scroll', this.handleScroll)
    },

    destroyed () {
      window.removeEventListener('scroll', this.handleScroll)
    },

    methods: {
      handleScroll () {
        this.scrollPosition = window.scrollY
      }
    }
  }
</script>
