import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import VueScrollTo from 'vue-scrollto'
import VueAnalytics from 'vue-analytics'
import VueCookies from 'vue-cookies'
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueAnalytics, {
  id: process.env.VUE_APP_GOOGLE_ANALYTICS,
  router
})

Vue.use(VueGoogleMaps, {
  load: window['__PRERENDER_INJECTED']
      ? null
      : { key: 'AIzaSyDReG_6l9-EeeaOtJTM-GkM-okUiWEaxSc' },
  installComponents: true
})

Vue.use(VueScrollTo)
Vue.use(VueCookies)

Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')
